import axios from "axios"

const directus = axios.create({
	baseURL: process.env.REACT_APP_DIRECTUS,
	headers: { Authorization: "Bearer uO49hWAHidvg97e0LXI9MU8oTWKJlYbv" },
	withCredentials: true,
})

const directusWrapper = function (options) {
	const onSuccess = function (response) {
		return response.data.data
	}

	const onError = function (error) {
		return Promise.reject(error.response || error.message)
	}

	return directus(options).then(onSuccess).catch(onError)
}

function directusGet(url) {
	return directusWrapper({
		url: url,
		method: "GET",
	})
}

function directusPost(url, data) {
	let options = {
		url: url,
		method: "POST",
	}

	if (data) {
		options["data"] = data
	}

	return directusWrapper(options)
}

function directusPatch(url, data) {
	let options = {
		url: url,
		method: "PATCH",
	}

	if (data) {
		options["data"] = data
	}

	return directusWrapper(options)
}

const directusAPI = {
	get: directusGet,
	post: directusPost,
	patch: directusPatch,
}

export default directusAPI
